import * as React from "react";
const CarWashTab = (props) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_0_407)">
      <path
        d="M58.0972 13.5747L45.3332 4.63867V0H42.6665V4H37.3332V0H34.6665V4.63867L21.9025 13.572C21.298 13.9925 21.1487 14.8233 21.569 15.4278C21.8189 15.787 22.2289 16.0008 22.6665 16H57.3332C58.0695 15.9995 58.666 15.402 58.6655 14.6657C58.6652 14.2312 58.453 13.824 58.0972 13.5747ZM26.8959 13.3333L36.4199 6.66667H43.5799L53.1039 13.3333H26.8959Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M17.3332 52H14.6665C12.4573 52 10.6665 53.7908 10.6665 56V57.3333C10.6665 59.5425 12.4573 61.3333 14.6665 61.3333H17.3332C19.5423 61.3333 21.3332 59.5425 21.3332 57.3333V56C21.3332 53.7908 19.5423 52 17.3332 52ZM18.6665 57.3333C18.6665 58.0697 18.0695 58.6667 17.3332 58.6667H14.6665C13.9302 58.6667 13.3332 58.0697 13.3332 57.3333V56C13.3332 55.2637 13.9302 54.6667 14.6665 54.6667H17.3332C18.0695 54.6667 18.6665 55.2637 18.6665 56V57.3333Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M65.3332 52H62.6665C60.4573 52 58.6665 53.7908 58.6665 56V57.3333C58.6665 59.5425 60.4573 61.3333 62.6665 61.3333H65.3332C67.5423 61.3333 69.3332 59.5425 69.3332 57.3333V56C69.3332 53.7908 67.5423 52 65.3332 52ZM66.6665 57.3333C66.6665 58.0697 66.0695 58.6667 65.3332 58.6667H62.6665C61.9302 58.6667 61.3332 58.0697 61.3332 57.3333V56C61.3332 55.2637 61.9302 54.6667 62.6665 54.6667H65.3332C66.0695 54.6667 66.6665 55.2637 66.6665 56V57.3333Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M51.9998 52H27.9998C25.7907 52 23.9998 53.7908 23.9998 56V57.3333C23.9998 59.5425 25.7907 61.3333 27.9998 61.3333H51.9998C54.209 61.3333 55.9998 59.5425 55.9998 57.3333V56C55.9998 53.7908 54.209 52 51.9998 52ZM53.3332 57.3333C53.3332 58.0697 52.7362 58.6667 51.9998 58.6667H27.9998C27.2635 58.6667 26.6665 58.0697 26.6665 57.3333V56C26.6665 55.2637 27.2635 54.6667 27.9998 54.6667H51.9998C52.7362 54.6667 53.3332 55.2637 53.3332 56V57.3333Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M70.4745 48.0867L67.5185 46.904L58.6758 34.7453C57.4188 33.023 55.416 32.0033 53.2838 32H26.7158C24.5837 32.0033 22.5808 33.023 21.3238 34.7453L12.4812 46.904L9.52516 48.0867C6.98766 49.0918 5.3245 51.548 5.33316 54.2773V77.3333C5.33316 78.8062 6.527 80 7.99983 80H15.9998C17.4727 80 18.6665 78.8062 18.6665 77.3333V72H61.3332V77.3333C61.3332 78.8062 62.527 80 63.9998 80H71.9998C73.4727 80 74.6665 78.8062 74.6665 77.3333V54.2773C74.6752 51.548 73.012 49.0918 70.4745 48.0867ZM23.4798 36.3147C24.234 35.2808 25.4362 34.6687 26.7158 34.6667H53.2838C54.5635 34.6687 55.7657 35.2808 56.5198 36.3147L64.0478 46.6667H15.9518L23.4798 36.3147ZM15.9998 77.3333H7.99983V71.772C8.11183 71.812 8.23316 71.8333 8.3505 71.8627C8.42783 71.8827 8.5025 71.9093 8.5825 71.924C8.82966 71.973 9.08116 71.9985 9.33316 72H15.9998V77.3333ZM71.9998 77.3333H63.9998V72H70.6665C70.9185 71.9985 71.17 71.973 71.4172 71.924C71.4972 71.9093 71.5718 71.8827 71.6505 71.8627C71.7665 71.8333 71.8878 71.812 71.9998 71.772V77.3333ZM71.9998 68C71.9998 68.7363 71.4028 69.3333 70.6665 69.3333H17.3332H9.33316C8.59683 69.3333 7.99983 68.7363 7.99983 68V66.6667H71.9998V68ZM71.9998 64H7.99983V54.2773C7.99416 52.64 8.992 51.1665 10.5145 50.564L13.5892 49.3333H66.4105L69.4852 50.564C71.0077 51.1665 72.0055 52.64 71.9998 54.2773V64Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M41.3332 18.6667H38.6665V22.6667H41.3332V18.6667Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M41.3332 25.3333H38.6665V29.3333H41.3332V25.3333Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M25.6363 17.8211L23.1015 20.9155L25.1644 22.6054L27.6992 19.511L25.6363 17.8211Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M21.4133 22.98L18.8781 26.074L20.9407 27.7641L23.4759 24.6701L21.4133 22.98Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M17.1882 28.1365L14.653 31.2305L16.7157 32.9206L19.2509 29.8266L17.1882 28.1365Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M12.9613 33.2988L10.4261 36.3928L12.4888 38.083L15.024 34.989L12.9613 33.2988Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M32.0539 18.2988L30.9327 22.1384L33.4924 22.8859L34.6136 19.0463L32.0539 18.2988Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M30.1848 24.6884L29.062 28.5276L31.6215 29.2761L32.7443 25.4369L30.1848 24.6884Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M18.0703 17.4125L15.1299 20.1245L16.937 22.0838L19.8774 19.3718L18.0703 17.4125Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M13.1684 21.9367L10.228 24.6487L12.0351 26.608L14.9755 23.896L13.1684 21.9367Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M8.27091 26.4535L5.33051 29.1655L7.1376 31.1248L10.078 28.4128L8.27091 26.4535Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M3.37186 30.9738L0.431458 33.6858L2.23855 35.6451L5.17895 32.9331L3.37186 30.9738Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M54.3664 18.1006L52.304 19.7891L54.838 22.8843L56.9004 21.1958L54.3664 18.1006Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M58.5838 23.2498L56.5209 24.9397L59.0557 28.0341L61.1186 26.3442L58.5838 23.2498Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M62.8116 28.411L60.749 30.1008L63.2838 33.1948L65.3464 31.505L62.8116 28.411Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M67.0267 33.574L64.9641 35.2639L67.4989 38.3579L69.5615 36.668L67.0267 33.574Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M47.9455 18.5883L45.3855 19.335L46.5055 23.175L49.0655 22.4283L47.9455 18.5883Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M49.8125 24.968L47.253 25.7163L48.3754 29.5555L50.9349 28.8072L49.8125 24.968Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M61.9265 17.6872L60.1194 19.6462L63.0594 22.3582L64.8665 20.3992L61.9265 17.6872Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M66.8293 22.2112L65.0219 24.1699L67.9615 26.8823L69.7689 24.9236L66.8293 22.2112Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M71.724 26.7336L69.9166 28.6924L72.8562 31.4048L74.6636 29.446L71.724 26.7336Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
      <path
        d="M76.6257 31.249L74.8186 33.208L77.7586 35.92L79.5657 33.961L76.6257 31.249Z"
        fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
        fillOpacity={props.isactive === "true" ? 1 : 0.5}
      />
    </g>
    <defs>
      <clipPath id="clip0_0_407">
        <rect
          width={80}
          height={80}
          fill={props.isactive === "true" ? "#FF5757" : "#4E4E4E"}
          fillOpacity={props.isactive === "true" ? 1 : 0.5}
        />
      </clipPath>
    </defs>
  </svg>
);
export default CarWashTab;
